import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const storagePoolsSelector = createSharedSelector(
  getDataSelector<DataKeys.StoragePools>(DataKeys.StoragePools),
  (pools) => {
    return pools.map((pool) => {
      return {
        ...pool,
      }
    })
  },
)
