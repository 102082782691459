import React, { useEffect, useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick, prop } from 'ramda'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import {
  listResmgrHosts,
  listHostAggregates,
  createHostAggregate,
  listHypervisors,
  getResmgrRoleConfig,
} from '../actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { resmgrHostsSelector, hostAggregatesSelector, hypervisorsSelector } from '../selectors'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import useReactRouter from 'use-react-router'
import { durationBetweenDates } from 'utils/misc'
import Text from 'core/elements/Text'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { routes } from 'core/utils/routes'
import { IHostDetailsPageTabs } from './host-details/model'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { useSelector } from 'react-redux'
import StatsCell from 'app/plugins/infrastructure/components/common/cells/StatsCell'
import HostsListPageHeader from './HostsListPageHeader'
import ApiClient from 'api-client/ApiClient'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import EditServerRolesModal from './EditServerRolesModal'
import ToggleMaintenanceModeDialog from './ToggleMaintenanceModeDialog'
import AuthorizeVmwareGatewayModal from './AuthorizeVmwareGatewayModal'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import FixMissingRolesDialog from './FixMissingRolesDialog'
import SimpleLink from 'core/components/SimpleLink'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'

type ModelDataKey = DataKeys.ResmgrHosts
type SelectorModel = ArrayElement<ReturnType<typeof resmgrHostsSelector>>
type ActionParams = InferActionParams<typeof listResmgrHosts>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const defaultParams: Params = {
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'ResmgrHosts',
  listTablePrefs,
)

const searchTargets = ['name', 'id', 'displayRoles', 'interfacesSearch']

export const IpAddressCellComponent = ({ value }) => {
  return (
    <div>
      {value.map((iface) => (
        <Text key={iface.ip} variant="body2">
          {iface.ip} ({iface.name})
        </Text>
      ))}
    </div>
  )
}

const HypervisorIncomplete = ({ host }) => {
  const classes = useStyles({})
  const [open, setOpen] = useState(false)
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {open && <FixMissingRolesDialog host={host} handleClose={() => setOpen(false)} />}
      <SimpleLink onClick={(e) => setOpen(true)}>
        <FontAwesomeIcon className={classes.missingPackages}>triangle-exclamation</FontAwesomeIcon>
        Missing Packages
      </SimpleLink>
    </div>
  )
}

const getStatusText = (status, roles) => {
  return status ? status : roles.length ? 'converging' : 'unauthorized'
}

export const HostStatusCell = ({ value, item }) => {
  const responding = item.info?.responding
  const classes = useStyles({ state: value, responding })
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'
  const roles = item.roles
  return (
    <div className={classes.hostStatusCell}>
      {responding ? (
        <div>
          {['converging', 'retrying'].includes(value) && (
            <FontAwesomeIcon className={classes.marginRight} spin>
              sync
            </FontAwesomeIcon>
          )}
          <Text
            className={!['converging', 'retrying'].includes(value) && classes.circle}
            variant="body2"
            component="span"
          >
            {getStatusText(value, roles)}
          </Text>
        </div>
      ) : roles.length ? (
        <div>
          <Text className={classes.circle} variant="body2" component="span">
            offline
          </Text>
        </div>
      ) : (
        <div>
          <Text variant="body2" component="span">
            {getStatusText(value, roles)}
          </Text>
        </div>
      )}
      {/* This part not needed I don't think with new uber roles */}
      {/* {!isVmware && item?.hypervisorRolesIncomplete && !!item.hypervisor && (
        <HypervisorIncomplete host={item} />
      )} */}
      {isVmware && item?.vmwareRolesIncomplete && (
        <div className={classes.warning}>
          <FontAwesomeIcon className={classes.missingPackages}>
            triangle-exclamation
          </FontAwesomeIcon>
          <Text variant="body2">vSphere Gateway Authorization Incomplete</Text>
        </div>
      )}
    </div>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.hostDetails.path({ id, tab: IHostDetailsPageTabs.Overview }),
    }),
  },
  {
    key: 'id',
    label: 'ID',
    display: false,
  },
  {
    key: 'role_status',
    label: 'Status',
    CellComponent: HostStatusCell,
  },
  {
    key: 'hypervisor.status',
    label: 'Scheduling',
    render: (value, item) => {
      if (!item.hypervisor) {
        return 'N/A'
      }
      return value === 'disabled' ? 'Disabled' : 'Enabled'
    },
  },
  {
    key: 'interfaces',
    label: 'IP Address',
    CellComponent: IpAddressCellComponent,
  },
  {
    key: 'usage',
    label: 'Resource Utilization',
    CellComponent: StatsCell,
  },
  {
    key: 'displayRoles',
    label: 'Assigned Roles',
    render: (values) => values.join(', '),
  },
]

export default function HostsListPage() {
  const { resMgr } = ApiClient.getInstance()
  const hasResmgr = resMgr.endpointPresent()
  const classes = useStyles({})

  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session
  const isVmware = features?.experimental?.pmov2_du_type === 'vmware'

  const [selectedHosts, setSelectedHosts] = useState([])
  const [showEditServerRolesModal, setShowEditServerRolesModal] = useState(false)
  const [showToggleMaintenanceModal, setShowToggleMaintenanceModal] = useState(false)
  const [showAuthorizeVmwareGatewayModal, setShowAuthorizeVmwareGatewayModal] = useState(false)

  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)

  const { loading: loadingHypervisors, reload: reloadHypervisors } = useListAction(
    listHypervisors,
    { params },
  )
  const { message, loading, reload } = useListAction(listResmgrHosts, {
    params,
  })
  const hosts = useSelector(resmgrHostsSelector)
  const hypervisors = useSelector(hypervisorsSelector)
  const hostsWithHypervisor = useMemo(() => {
    const hypervisorsByResmgrId = hypervisors.reduce((accum, hypervisor) => {
      return {
        ...accum,
        [hypervisor?.service?.host]: hypervisor,
      }
    }, {})
    return hosts.map((host) => {
      return {
        ...host,
        hypervisor: hypervisorsByResmgrId[host.id],
      }
    })
  }, [hosts, hypervisors])

  // Todo: Need to query host roles and see if they are equal to the blueprint values, check with backend on plausibility of this

  const batchActions: GridBatchActionSpec<SelectorModel>[] = isVmware
    ? [
        {
          cond: (hosts) => {
            return (
              hosts.length === 1 &&
              hosts[0]?.extensions?.hypervisor_details?.data?.hypervisor_type === 'VMWareCluster'
            )
          },
          label: 'Manage VMware Gateway Configuration',
          icon: 'person-to-portal',
          handleAction: (selected) => {
            setSelectedHosts(selected)
            setShowAuthorizeVmwareGatewayModal(true)
          },
          refreshAfterSuccess: true,
        },
        {
          cond: (hosts) => {
            return hosts.length > 0 && hosts.every((host) => host?.hypervisor)
          },
          disabledTooltip: 'Cordon is only applicable to active hypervisors',
          label: 'Cordon/Uncordon',
          icon: 'calendar-xmark',
          handleAction: (selected) => {
            setSelectedHosts(selected)
            setShowToggleMaintenanceModal(true)
          },
          refreshAfterSuccess: true,
        },
      ]
    : [
        {
          cond: (hosts) => {
            return hosts.length > 0
          },
          label: 'Assign Cluster Roles',
          icon: 'pen-to-square',
          handleAction: (selected) => {
            setSelectedHosts(selected)
            setShowEditServerRolesModal(true)
          },
          refreshAfterSuccess: true,
        },
        {
          cond: (hosts) => {
            return hosts.length > 0 && hosts.every((host) => host?.hypervisor)
          },
          disabledTooltip: 'Cordon is only applicable to active hypervisors',
          label: 'Cordon/Uncordon',
          icon: 'calendar-xmark',
          handleAction: (selected) => {
            setSelectedHosts(selected)
            setShowToggleMaintenanceModal(true)
          },
          refreshAfterSuccess: true,
        },
      ]

  return (
    <>
      <DocumentMeta title="Cluster Hosts" />
      {hasResmgr ? (
        <>
          {showEditServerRolesModal && (
            <EditServerRolesModal
              onClose={() => setShowEditServerRolesModal(false)}
              rows={selectedHosts}
            />
          )}
          {showToggleMaintenanceModal && (
            <ToggleMaintenanceModeDialog
              onClose={() => setShowToggleMaintenanceModal(false)}
              rows={selectedHosts}
            />
          )}
          {showAuthorizeVmwareGatewayModal && (
            <AuthorizeVmwareGatewayModal
              onClose={() => setShowAuthorizeVmwareGatewayModal(false)}
              rows={selectedHosts}
            />
          )}
          {!!hosts?.length && <HostsListPageHeader hosts={hosts} />}
          <ListContainer<ModelDataKey, SelectorModel>
            dataKey={DataKeys.ResmgrHosts}
            searchTargets={searchTargets}
            uniqueIdentifier="id"
            addUrl={
              isVmware ? routes.openstack.addVmwareGateway.path() : routes.openstack.addHosts.path()
            }
            addText={isVmware ? 'Add VMware Gateway' : 'Add New Hosts'}
            loading={loading}
            loadingMessage={message}
            onRefresh={() => {
              reload(true)
              reloadHypervisors(true)
            }}
            data={hostsWithHypervisor}
            columns={columns}
            getParamsUpdater={getParamsUpdater}
            batchActions={batchActions}
            multiSelection
            {...pick(listTablePrefs, params)}
          />
        </>
      ) : (
        <div className={classes.noResmgr}>
          <Text variant="subtitle1">
            Host management is only available with Platform9 Managed OpenStack
          </Text>
        </div>
      )}
    </>
  )
}

const getIconOrBubbleColor = (status, theme: Theme) =>
  ({
    ok: theme.palette.green.main,
    failed: theme.palette.red.main,
  }[status] || theme.palette.grey[500])

const useStyles = makeStyles<Theme, { state?: string; responding?: boolean }>((theme) => ({
  noResmgr: {
    padding: '40px',
    textAlign: 'center',
    background: 'white',
  },
  marginRight: {
    marginRight: 8,
  },
  circle: {
    display: 'inline-grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(22px, max-content) 1fr',
    whiteSpace: 'nowrap',
    gridGap: 5,
    justifyItems: 'center',
    '&:before': {
      content: '""',
      display: 'inherit',
      height: 12,
      width: 12,
      borderRadius: '50%',
      backgroundColor: ({ state, responding }) =>
        responding ? getIconOrBubbleColor(state, theme) : theme.palette.red.main,
    },
  },
  hostStatusCell: {
    display: 'grid',
    gap: 8,
  },
  missingPackages: {
    color: theme.palette.yellow.main,
    marginRight: 6,
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
  },
}))
