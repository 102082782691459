import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'

const { cinder } = ApiClient.getInstance()

export const storagePoolActions = ActionsSet.make<DataKeys.StoragePools>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.StoragePools,
  cacheKey: DataKeys.StoragePools,
  addCacheKey: DataKeys.StoragePools,
})

export const listStoragePools = storagePoolActions.add(
  new ListAction<DataKeys.StoragePools>(async () => {
    return cinder.getStoragePools()
  }),
)
